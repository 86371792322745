import * as axios from "axios";
import { getToken } from "./store";

let api_url =
  process.env.NODE_ENV === "production" ? "https://api.bengaltea.fleapo.co.in/api/" : "http://localhost:7999/api/";

class apiCall {
  post = (url, data, token = null) =>
    new Promise((resolve, reject) => {
      token = getToken();

      var headers = {
        Authorization: "Bearer " + token,
      };

      axios
        .post(api_url + url, data, token !== null ? { headers } : undefined)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });

  get = (url, token) =>
    new Promise((resolve, reject) => {
      token = getToken();
      var headers = {
        Authorization: "Bearer " + token,
      };

      axios
        .get(api_url + url, { headers })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
}

export default new apiCall();
