import apiCall from "./apiCall";

export const getUser = () => {
  let activeUser = localStorage.getItem("activeUser") === null ? false : JSON.parse(localStorage.getItem("activeUser"));
  return activeUser;
};

export const getToken = () => {
  let token = localStorage.getItem("token") !== null ? localStorage.getItem("token") : false;
  return token;
};

export const getAllGardens = () => {
  apiCall.get("admin/getAllGardens").then((response) => {
    if (response.data.status === true) {
      return response.data.response;
    } else {
      return false;
    }
  });
};

export const getAllDivisions = () => {
  apiCall.get("admin/getAllDivisions").then((response) => {
    if (response.data.status === true) {
      return response.data.response;
    } else {
      return false;
    }
  });
};

export const getAllSections = () => {
  apiCall.get("admin/getAllSections").then((response) => {
    if (response.data.status === true) {
      return response.data.response;
    } else {
      return false;
    }
  });
};

export const getAllSubAdmins = () => {
  apiCall.get("admin/getAllSubAdmins").then((response) => {
    if (response.data.status === true) {
      return response.data.response;
    } else {
      return false;
    }
  });
};

export const getClone = () => {
  apiCall.get("admin/getClone").then((response) => {
    if (response.data.status === true) {
      return response.data.response;
    } else {
      return false;
    }
  });
};

export const logoff = () => {};
