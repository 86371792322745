import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { getToken } from "./utils/store";

import { Spin } from "antd";
import "./App.css";

const Login = lazy(() => import("./common/Login"));

const configureFields = lazy(() => import("./forms/configureFields"));
const leafClassificationForm = lazy(() => import("./forms/leafClassificationForm"));
const leafCountForm = lazy(() => import("./forms/leafCountForm"));
const manuringApplicationForm = lazy(() => import("./forms/manuringApplicationForm"));
const manuringSoilTest = lazy(() => import("./forms/manuringSoilTest"));
const pestManagementActionTaken = lazy(() => import("./forms/pestManagementActionTaken"));
const pestManagementGuide = lazy(() => import("./forms/pestManagementGuide"));
const pestManagementIssueDetection = lazy(() => import("./forms/pestManagementIssueDetection"));
const pluckingForm = lazy(() => import("./forms/pluckingForm"));
const pruningExecutionForm = lazy(() => import("./forms/pruningExecutionForm"));
const pruningPlanningForm = lazy(() => import("./forms/pruningPlanningForm"));
const weatherForm = lazy(() => import("./forms/weatherForm"));

const editPrune = lazy(() => import("./forms/pruningPlanningForm/editPrune"));

const dashboard = lazy(() => import("./reports/dashboard"));
const leafAnalysisReport = lazy(() => import("./reports/leafAnalysisReport"));
const manuringReport = lazy(() => import("./reports/manuringReport"));
const pestReport = lazy(() => import("./reports/pestReport"));
const roundageReport = lazy(() => import("./reports/roundageReport"));
const pruningReport = lazy(() => import("./reports/pruningReport"));

const divisionListing = lazy(() => import("./listing/divisions.js"));
const fieldListing = lazy(() => import("./listing/fields.js"));
const gardenListing = lazy(() => import("./listing/gardens.js"));
const issueListing = lazy(() => import("./listing/issues.js"));
const leafClassificationListing = lazy(() => import("./listing/leafClassification.js"));
const leafCountListing = lazy(() => import("./listing/leafCount.js"));
const manureApplicationListing = lazy(() => import("./listing/manureApplication.js"));
const pestActionTakenListing = lazy(() => import("./listing/pestActionTaken.js"));
const pestDetectedListing = lazy(() => import("./listing/pestDetected.js"));
const pestFollowUpListing = lazy(() => import("./listing/pestFollowUp.js"));
const plotListing = lazy(() => import("./listing/plots.js"));
const pluckingFormListing = lazy(() => import("./listing/pluckingForm.js"));
const roundageListing = lazy(() => import("./listing/roundage.js"));
const sectionListing = lazy(() => import("./listing/sections.js"));
const soilTestListing = lazy(() => import("./listing/soilTest.js"));
const userListing = lazy(() => import("./listing/users.js"));
const vendorListing = lazy(() => import("./listing/vendors.js"));
const weatherListing = lazy(() => import("./listing/weather.js"));

let token = getToken();

const ProtectedRoute = ({ ...props }) => (token !== false ? <Route {...props} /> : <Redirect to="/" />);

export default class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    this.setState({});
  }
  render() {
    return (
      <Router>
        <Suspense
          fallback={
            <div className="spin-container">
              <Spin
                style={{
                  backgroundColor: "white",
                  color: "#086e3f"
                }}
                spinning={true}
                tip="Please Wait..."></Spin>
            </div>
          }>
          <Switch>
            <Route exact path="/" component={Login} />

            <ProtectedRoute exact path="/dashboard" component={dashboard} />
            <ProtectedRoute exact path="/gardenposition-plucking" component={roundageReport} />
            <ProtectedRoute exact path="/leafanalysisreport" component={leafAnalysisReport} />
            <ProtectedRoute exact path="/gardenposition-pest" component={pestReport} />
            <ProtectedRoute exact path="/gardenposition-manuring" component={manuringReport} />
            <ProtectedRoute exact path="/gardenposition-pruning" component={pruningReport} />

            <ProtectedRoute exact path="/configure" component={configureFields} />
            <ProtectedRoute exact path="/pluckingform" component={pluckingForm} />
            <ProtectedRoute exact path="/pruningexecutionform" component={pruningExecutionForm} />
            <ProtectedRoute exact path="/pruningplanningform" component={pruningPlanningForm} />
            <ProtectedRoute exact path="/pestmanagementactiontaken" component={pestManagementActionTaken} />
            <ProtectedRoute exact path="/pestmanagementguide" component={pestManagementGuide} />
            <ProtectedRoute exact path="/pestmanagementissuedetection" component={pestManagementIssueDetection} />
            <ProtectedRoute exact path="/weatherform" component={weatherForm} />
            <ProtectedRoute exact path="/leafcountform" component={leafCountForm} />
            <ProtectedRoute exact path="/leafclassificationform" component={leafClassificationForm} />
            <ProtectedRoute exact path="/manuringapplicationform" component={manuringApplicationForm} />
            <ProtectedRoute exact path="/manuringsoiltest" component={manuringSoilTest} />

            <ProtectedRoute exact path="/listing-divisions" component={divisionListing} />
            <ProtectedRoute exact path="/listing-fields" component={fieldListing} />
            <ProtectedRoute exact path="/listing-gardens" component={gardenListing} />
            <ProtectedRoute exact path="/listing-issues" component={issueListing} />
            <ProtectedRoute exact path="/listing-leafClassification" component={leafClassificationListing} />
            <ProtectedRoute exact path="/listing-leafCount" component={leafCountListing} />
            <ProtectedRoute exact path="/listing-manureApplication" component={manureApplicationListing} />
            <ProtectedRoute exact path="/listing-pestActionTaken" component={pestActionTakenListing} />
            <ProtectedRoute exact path="/listing-pestDetected" component={pestDetectedListing} />
            <ProtectedRoute exact path="/listing-pestFollowUp" component={pestFollowUpListing} />
            <ProtectedRoute exact path="/listing-plots" component={plotListing} />
            <ProtectedRoute exact path="/listing-pluckingForm" component={pluckingFormListing} />
            <ProtectedRoute exact path="/listing-roundage" component={roundageListing} />
            <ProtectedRoute exact path="/listing-sections" component={sectionListing} />
            <ProtectedRoute exact path="/listing-soilTest" component={soilTestListing} />
            <ProtectedRoute exact path="/listing-users" component={userListing} />
            <ProtectedRoute exact path="/listing-vendors" component={vendorListing} />
            <ProtectedRoute exact path="/listing-weather" component={weatherListing} />
            <ProtectedRoute exact path="/edit-prune" component={editPrune} />
          </Switch>
        </Suspense>
      </Router>
    );
  }
}
